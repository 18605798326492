html,
body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
}
.container {
	margin: 80px auto 0 auto;
	max-width: 1200px;
}
.nav-container {
	margin: auto;
}
.match-header {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 40%;
	border: 3px solid green;
}
.loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: fixed;
	justify-content: center;
	align-items: center;
}
